<template>
    <div :class="$style.conversionSettings">conversionSettings</div>
</template>

<script>
export default {
    name: 'ConversionSettings'
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.conversionSettings {
}
</style>